<template>
	<div class="userconten">
		<div class="liubai">
			
			<div class="content">
				<el-form ref="ruleForm" :model="ruleForm" label-width="80px" :rules="rules">
					<el-form-item label="账号" prop="admin" :required="true">
						<el-input type="admin" v-model="ruleForm.admin" autocomplete="off" :placeholder='this.olddata==null?"请输入账号":this.olddata.admin_name'></el-input>
					</el-form-item>
					<el-form-item label="成员姓名" prop="compellation" :required="true">
						<el-input type="compellation" v-model="ruleForm.compellation" autocomplete="off" :placeholder='this.olddata==null?"请输入姓名":this.olddata.admin_user'></el-input>
					</el-form-item>


					<el-form-item label="手机号" prop="tel" :required="true">
						<el-input type="phone" v-model="ruleForm.tel" autocomplete="off" :placeholder='this.olddata==null?"请输入手机号":this.olddata.admin_tel'></el-input>
					</el-form-item>

					<el-form-item prop="email" label="邮箱" :rules="[
					      { required: true, message: '请输入邮箱地址', trigger: 'blur' },
					      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
					    ]">
						<el-input v-model="ruleForm.email" :placeholder='this.olddata==null?"请输入邮箱":this.olddata.admin_email'></el-input>
					</el-form-item>
					<el-form-item label="角色" :required="true">
						<el-select v-model="ruleForm.region" placeholder="选择角色">
							<el-option v-for="item,index in data" :key='index' :label="item.role_name" :value="item.role_id"></el-option>
						</el-select>
					</el-form-item>
					<!-- <div class="hint">选择角色后默认继承角色权限，可在管理员列表中单独设置权限</div> -->
					<el-form-item label="旧密码" prop="oldpass" :required="true" v-show="olddata">
						<el-input type="password" v-model="ruleForm.oldpass" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="密码" prop="pass" :required="true">
						<el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="checkPass" :required="true">
						<el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>

					</el-form-item>
					<el-form-item label="备注信息">
						<el-input type="textarea" :rows="6" v-model="ruleForm.desc" :placeholder='this.olddata==null?"暂无":this.olddata.remarks'></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
					</el-form-item>

				</el-form>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'userconten',
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					if (this.ruleForm.checkPass !== '') {
						this.$refs.ruleForm.validateField('checkPass');
					}
					callback();
				}
			};


			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.ruleForm.pass) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};

			var validatePass3 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入旧密码'));
				} else {
					callback();
				}
			};

			var checkPhone = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('手机号不能为空'))
				} else {
					var reg = /^1[3|4|5|7|8][0-9]\d{8}$/
					if (reg.test(value)) {
						callback()
					} else {
						return callback(new Error('请输入正确的手机号'))
					}

					if (value.length > 11) {
						return callback(new Error('请输入正确的手机号'))
					}
				}
			}
			var checkPhone2 = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('账号不能为空'))
				} else {
					callback();
				}

			}
			var checkPhone3 = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('姓名不能为空'))
				} else {
					callback();
				}
			}
			return {
				data: null,
				flag: false,
				olddata: null,
				rules: {
					pass: [{
						validator: validatePass,
						trigger: 'blur'
					}],
					checkPass: [{
						validator: validatePass2,
						trigger: 'blur'
					}],
					tel: [{
						validator: checkPhone,
						trigger: 'blur'
					}],
					admin: [{
						validator: checkPhone2,
						trigger: 'blur'
					}],
					compellation: [{
						validator: checkPhone3,
						trigger: 'blur'
					}],
					oldpass: [{
						validator: validatePass3,
						trigger: 'blur'
					}],
				},
				ruleForm: {
					admin: '',
					region: null,
					desc: '',
					pass: '',
					checkPass: '',
					email: '',
					compellation: '',
					tel: '',
					oldpass: '',
				},

			}
		},
		components: {

		},
		beforeCreate() {
			let token = this.$storage.getLocal('token');
			this.$request.selRoleData({
				token
			}).then(res => {
				this.data = res.data;
			})
			if (this.$route.query.id) {
				this.flag = true
				let id = this.$route.query.id;
				this.$request.updateAdminSel({
					token,
					id
				}).then(res => {
					if (res.code == 0) {
						this.olddata = res.data;
						this.ruleForm.region = res.data.role_id;
					}
				})
			} else {
				this.flag = false
			}
			// console.log(this.flag);


		},
		created() {},
		beforeMount() {},
		methods: {
			onSubmit() {
				// console.log('submit!');
			},
			updateAdmin(formName) {
				

			},
			
			submitForm(formName) {
				// console.log(1111)
				
				if(this.olddata==null){
					this.$refs[formName].validate((valid) => {
						console.log(valid)
							let token = this.$storage.getLocal('token');
							this.$request.addAdmin({
								token,
								name: this.ruleForm.admin,
								user: this.ruleForm.compellation,
								email: this.ruleForm.email,
								pass: this.ruleForm.pass,
								checkPass: this.ruleForm.checkPass,
								tel: this.ruleForm.tel,
								remarks: this.ruleForm.desc,
								role_id: this.ruleForm.region
							}).then(res => {
								if (res.code == 0) {
									this.$notify({
										title: '成功',
										message: '添加成功',
										type: 'success'
									});
									this.$refs['ruleForm'].resetFields();
								} else {
									this.$notify({
										title: '警告',
										message: '添加失败',
										type: 'warning'
									})
								}
								// console.log(res)
							})
						
					});
				}else{
					// console.log(this.olddata)
					this.$refs[formName].validate((valid) => {
							// alert('submit!');
							// console.log(this.ruleForm)
							let token = this.$storage.getLocal('token');
							this.$request.updateAdmin({
								token,
								name: this.ruleForm.admin,
								user: this.ruleForm.compellation,
								email: this.ruleForm.email,
								pass: this.ruleForm.pass,
								checkPass: this.ruleForm.checkPass,
								tel: this.ruleForm.tel,
								remarks: this.ruleForm.desc,
								role_id: this.ruleForm.region,
								oldpass: this.ruleForm.oldpass,
								admin_id: this.olddata.admin_id
							}).then(res => {
								if (res.code == 0) {
									this.$notify({
										title: '成功',
										message: '添加成功',
										type: 'success'
									});
									this.$refs['ruleForm'].resetFields();
								} else {
									this.$notify({
										title: '警告',
										message: '添加失败',
										type: 'warning'
									})
								}
								// console.log(res)
							})
					});
				}
				
				
				
				
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		},
		computed: {

		},
	}
</script>

<style scoped="scoped">
	.userconten {
		font-size: 20px;
		width: 85%;
		margin-left: 14.5%;
	}

	.liubai {
		width: 96%;
		margin: 0 auto;
	}

	.title {
		font-size: 20px;
		height: 30px;
		line-height: 30px;
		font-weight: 800;
		text-align: left;
	}

	.content {
		border: 1px solid #E4E4E4;
		background-color: #FFFFFF;
		padding-top: 100px;
		padding-bottom: 100px;
	}

	form {
		width: 50%;
		margin: 0 auto;
	}
</style>
