<template>
	<div class="addUser">
		<tabbar :top1="top1" :top2="top2">
			<div class="titles1" style="margin-top: 10px;margin-right: 62%;">权限>角色设置</div>
			<adduser></adduser>
		</tabbar>
	</div>
</template>

<script>
	import tabbar from '../public/Frame.vue'
	import adduser from './comp/Userconten.vue'
	export default {
		name: 'addUser',
		data() {
			return {
				top1: '3-3',
				top2: ['3'],
			}
		},
		components: {
			adduser,
			tabbar
		},
		created() {
			
		},
		methods: {
			
		},
		computed: {

		},
	}
</script>

<style scoped="scoped">
	.addUser
	{
	background-color: rgb(245,247,249);
	height: 800px;
	}
</style>
